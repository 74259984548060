<template>
  <v-card tile flat>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid>
      <v-row dense>
          <span class="title">Manage Agreements</span>
          <v-spacer></v-spacer>
          <tournament-agreement-drawer
            :agreements="statusData"
            :tournament="tournament"
          ></tournament-agreement-drawer>
          <v-btn color="color3" small text icon @click.stop="loadStatuses" :disabled="loading">
            <v-icon>fas fa-sync-alt</v-icon>
          </v-btn>
      </v-row>
    </v-container>
    <v-card-text class="pt-0">
      <v-divider class="mb-2"></v-divider>
      <v-window v-model="window" touchless>
        <v-window-item :value="0">
          <v-expansion-panels hover v-model="panel" multiple v-if="statusData && statusData.length > 0">
            <v-expansion-panel v-for="(agreement, i) in statusData" :key="agreement.id">
              <v-expansion-panel-header color="color1 color1Text--text" class="title">
                {{agreement.name}}
                <template v-slot:actions>
                  <v-icon color="color1Text">$expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="caption grey--text">*Required for {{ agreement.requiredFor | formatArray }} - Agreement Id: {{agreement.id}}</div>
                <v-text-field
                  color="color3"
                  append-icon="fas fa-search"
                  label="Search"
                  hide-details
                  v-model="search"
                  solo-inverted
                  clearable
                ></v-text-field>
                <v-data-table
                  :headers="[
                    {text: 'Player', value: 'player.fullname'},
                    {text: 'Division', value: 'division'},
                    {text: 'Status', value: 'status'},
                    {text: '', value: 'view'},
                  ]"
                  :items="agreement.players"
                  multi-sort
                  :footer-props="{'items-per-page-options': [25, 50, 100, -1]}"
                  item-key="key"
                  v-model="selected[i]"
                  show-select
                  :search="search"
                >
                  <template v-slot:[`item.status`]="{item}">
                    <v-chip :color="item.status === 'Signed' ? 'success' : item.status === 'Unsigned' ? 'error' : 'warning'">
                      <v-icon class="mr-2">
                        {{item.status === 'Signed' ? 'far fa-check-circle' : item.status === 'Unsigned' ? 'far fa-times-circle' : 'far fa-exclamation-circle'}}
                      </v-icon>
                      {{item.status}}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.view`]="{item}">
                    <player-agreement-dialog
                      v-if="item.status !== 'Unsent'"
                      :playerProfileId="item.player.playerProfileId"
                      :agreementId="agreement.id"
                      :agreement="agreement.name"
                      color="color3"
                    ></player-agreement-dialog>
                  </template>
                </v-data-table>
                <v-fab-transition>
                  <v-btn
                    color="color3 color3Text--text"
                    v-if="selected[i] && selected[i].length"
                    :fixed="!isIntersecting"
                    :bottom="!isIntersecting"
                    :center="!isIntersecting"
                    @click.stop="resendClick(i, agreement.id)"
                    :disabled="loading"
                  >
                    Resend ({{selected[i].length}})
                  </v-btn>
                </v-fab-transition>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row dense v-else>
            <v-col cols="12" class="text-center text-h6">
              You do not have any required agreements for this event.
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="1">
          <v-card flat>
            <v-card-title>
              Resending {{resendName}}
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12" md="6" xl="4" v-for="dto in dtos" :key="dto.key">
                    <v-card>
                      <v-card-title class="pb-0">
                        {{dto.name}}
                      </v-card-title>
                      <v-card-text class="py-0">
                        <v-checkbox
                          label="Send to email addresses on file"
                          v-model="dto.emailsOnFile"
                          color="success"
                          :disabled="loading"
                        ></v-checkbox>
                          <v-row dense v-for="(alt, i) in dto.altEmailList" :key="`${dto.key}-${i}`">
                            <v-col cols="4" class="py-0">
                              <v-text-field
                                class="mt-0"
                                label="Name"
                                v-model="alt.name"
                                hide-details
                                :disabled="loading"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="py-0">
                              <v-text-field
                                class="mt-0"
                                label="Email"
                                v-model="alt.email"
                                hide-details
                                :disabled="loading"
                              ></v-text-field>
                            </v-col>
                            <v-col class="py-0 d-flex align-end">
                              <v-btn x-small color="error" text icon @click.stop="() => dto.altEmailList.splice(i)" :disabled="loading">
                                <v-icon>fas fa-trash</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="color3Text color3--text"
                          small fab
                          @click.stop="() => dto.altEmailList.push({name: null, email: null})"
                          :disabled="loading"
                        >
                          <v-icon>fas fa-plus</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-fab-transition>
                      <v-btn color="color3" text @click="showAddNote = true" v-if="!showAddNote">
                        Include a message
                      </v-btn>
                    </v-fab-transition>
                  </v-col>
                  <v-expand-transition>
                    <v-textarea
                      label="Message to send with the waiver"
                      v-if="showAddNote"
                      v-model="addNote"
                    ></v-textarea>
                  </v-expand-transition>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                fab
                :fixed="!isIntersecting"
                :bottom="!isIntersecting"
                :left="!isIntersecting"
                @click.stop="window=0"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
              <v-btn
                color="success"
                fab
                :fixed="!isIntersecting"
                :bottom="!isIntersecting"
                :left="!isIntersecting"
                :style="!isIntersecting ? 'margin-left: 60px' : null"
                @click.stop="sendThem"
                :loading="loading"
              >
                <v-icon>fas fa-paper-plane</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card-text>
    <div v-intersect="onIntersect"></div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'
const PlayerAgreementDialog = () => import('@/components/Agreements/PlayerAgreementDialog')
const TournamentAgreementDrawer = () => import('@/components/Agreements/TournamentAgreementDrawer')

export default {
  props: ['active'],
  data () {
    return {
      loading: false,
      window: 0,
      panel: [0],
      selected: [],
      dtos: null,
      resendName: null,
      showAddNote: false,
      addNote: null,
      search: null,
      isIntersecting: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    dialog: {
      get () { return this.pAgree.length > 0 },
      set (val) {
        if (!val) this.pAgree = []
      }
    },
    bottomNav: {
      get () {
        return this.selected.length > 0
      },
      set (val) {
        if (!val) {
          this.selected = []
        }
      }
    },
    statuses () {
      return this.tournament.agreementStatuses
    },
    statusData () {
      return this.statuses && this.statuses.map(s => {
        const mapDivisionNames = (divisionIds) => {
          const ids = divisionIds.split(',').map(m => +m)
          return ids.map(id => {
            const d = this.tournament.getDivision(id)
            return d.name
          })
        }
        return {
          name: s.agreement,
          id: s.agreementId,
          requiredFor: s.divisionIds === '*' ? 'All Participants' : mapDivisionNames(s.divisionIds),
          players: flatten(s.divisions.map(d => {
            const div = this.tournament.getDivision(d.divisionId)
            return div.activePlayers.map(p => {
              const status = d.signed.includes(p.playerProfileId) ? 'Signed' : d.signedPrev.includes(p.playerProfileId) ? 'Signed Previous Version' : d.unsigned.includes(p.playerProfileId) ? 'Unsigned' : d.unsent.includes(p.playerProfileId) ? 'Unsent' : 'NA'
              return {
                division: div.name,
                player: p,
                status: status,
                key: `${p.ppId}-${s.agreementId}-${div.id}`
              }
            })
          }))
        }
      })
    }
  },
  methods: {
    loadStatuses () {
      if (!this.active) return
      this.loading = true
      this.tournament.getAgreementStatus()
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    resendClick (i, agreementId) {
      const s = this.selected[i]
      const a = this.statusData[i]
      this.resendName = a.name
      this.dtos = s.map(p => {
        return {
          playerProfileId: p.player.playerProfileId,
          tournamentId: this.tournament.id,
          agreementId: a.id,
          name: p.player.name,
          emailsOnFile: true,
          altEmailList: [],
          key: p.key
        }
      })
      this.window = 1
      this.selected = []
      setTimeout(() => {
        this.$vuetify.goTo(0)
      }, 333)
    },
    sendThem () {
      if (!this.dtos) return
      this.loading = true
      this.$VBL.agreements.tournament.send({ toBeSent: this.dtos, addNote: this.addNote })
        .then(r => {
          this.tournament.agreementStatuses = r.data
          this.window = 0
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    onIntersect (entries, observer) {
      this.isIntersecting = entries[0].isIntersecting
    }
  },
  watch: {
    active: 'loadStatuses',
    window: function (val) {
      if (!val) {
        this.addNote = null
        this.showAddNote = false
      }
    }
  },
  components: {
    PlayerAgreementDialog,
    TournamentAgreementDrawer
  },
  mounted () {
    this.loadStatuses()
  }
}
</script>
